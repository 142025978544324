import React from 'react'
import Societe from './Societe'
import Certificat from "../../../asset/images/business.png"
import Societé from "../../../asset/images/societe.png"
import Actualite from "../../../asset/images/un-journal.png"
export default function FullSociete() {
  return (
    <div className='w-full my-10  flex items-center justify-center'>
        <div className='flex  justify-between items-center md:flex-row flex-col md:w-5/6 w-full'>
            <Societe item={1} img={Certificat} title="Certificats" desc="Découvrez nos certifications qui attestent de notre engagement pour la qualité et la conformité de nos produits et services."/>
            <Societe item={2} img={Societé} title="Société " desc="Apprenez-en davantage sur notre entreprise, nos valeurs et notre expertise dans l'importation, fabrication, et installation."/>
            <Societe item={3} img={Actualite} title="Actualité " desc="Restez informé des dernières nouvelles, événements et innovations concernant nos solutions et le secteur."/>
        </div>
    </div>
  )
}
