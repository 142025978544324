import {MenuItemsJ} from "./MenuItemsJ";
import MenuItemsC from "./MenuItemsC";
const Navbar = () => {
    return ( 
        <nav >
            <ul  className = "menus " style={{fontSize:'15px'}}> {
                MenuItemsJ.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItemsC items = {menu} key = {index} depthLevel={depthLevel}/>})}
            </ul> 
        </nav>
    );
};

export default Navbar;