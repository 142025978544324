import React from 'react'
import T2FDef from "../asset/images/Define.png"
import FullCounter from './CompAccueil/CounterDivElement/FullCounter'
import FullAventages from './CompAccueil/CompAventage/FullAventages'
import FullSolution from './CompAccueil/CompSolution/FullSolution'
import FullSolutionResp from './CompAccueil/CompSolution/FullSolutionResp'
import FullSociete from './CompAccueil/CompSocite/FullSociete'
export default function Accueil() {
return (
    <div>
        <div className='container flex items-center  flex-col justify-center m-auto p-6 '>
            <div className=' lg:w-10/12 w-full justify-between flex mb-28 md:mb-0' style={{height:"500px"}} >
                <div className=' flex items-end   w-full text-center lg:text-left lg:w-2/4  h-full'>
                    <div className=' w-full flex flex-col justify-evenly ' style={{height:"85%"}}>
                        <div className='font-bold text-emerald-500 ' style={{fontSize:"40px", height:"15%"}} >Pourquoi choisir T2F SYSTEMS</div>
                        <div className='text-xl text-gray-700 md:my-0 font-light mt-36 mb-10' >Parce que notre responsabilité est engagée dans le bon fonctionnement de nos solutions , nous  nous devons de vous garantir un niveau optimal de qualité , de fiabilité et de sécurité pour l'ensemble de nos solutions.</div>
                        <a href='' className='text-emerald-500 lg:w-2/5 w-full p-3 rounded-md hover:bg-emerald-500 hover:text-white duration-500  hover:cursor-pointer border-emerald-500 border-solid  flex justify-center font-bold' style={{borderWidth:"1px"}}>
                            <button style={{fontSize:"17px" }}>NOS SOLUTIONS</button>
                        </a>
                    </div>
                </div>
                <div  className='hidden lg:block h-full w-1/2' style={{backgroundImage:`url(${T2FDef})`,backgroundSize:"53%" , backgroundPositionX:"center" , backgroundPositionY:"bottom" , backgroundRepeat:"no-repeat"}}></div>
            </div>
        </div>
        <div className='my-24'>
            <div  className=' text-emerald-500 font-bold text-4xl text-center'>
                À propos de T2F SYSTEMS
            </div>
            <FullSociete/>
        </div>
        <div className='my-10'>

            <div  className=' text-emerald-500 font-bold text-4xl text-center'>
                Nos Atouts Qui Nous Distinguent
            </div>
            <div  className=' text-xl font-light mb-8 text-gray-700 flex justify-center text-center' >
                <p style={{width:"82%"}}>Chez T2F, nous mettons en avant nos points forts qui font notre succès , un développement logiciel de pointe, un service après-vente réactif, une approche innovante et créative, ainsi qu'une équipe de professionnels passionnés. </p>
            </div>
            <FullCounter/>
        </div>
            <div  className=' text-emerald-500 font-bold text-4xl  text-center'>
                Adapté à vos besoins
            </div>
        <FullAventages/>
        <div className='flex justify-center'>
            <a href='' className='text-emerald-500 px-10 mb-10  p-3 rounded-md hover:bg-emerald-500 hover:text-white duration-500  hover:cursor-pointer border-emerald-500 border-solid  font-bold' style={{borderWidth:"1px"}}>
                <button style={{fontSize:"17px" }}>ESPACE CLIENTS</button>
            </a>
        </div>
        <div  className=' text-emerald-500 font-bold text-4xl mt-10 mb-8 text-center'>
            Nos solutions d'expérience client
        </div>
        <div className='my-10'>
            <div className='hidden md:block'>
                <FullSolutionResp/>
            </div>
            <div className='md:hidden'>
                <FullSolution/>
            </div>

            {/* <div className='flex justify-center'>
                <a href='' className='text-emerald-500 px-10 mb-10  p-3 rounded-md hover:bg-emerald-500 hover:text-white duration-500  hover:cursor-pointer border-emerald-500 border-solid  font-bold' style={{borderWidth:"1px"}}>
                    <button style={{fontSize:"17px" }}>T2F SOLUTIONS</button>
                </a>
            </div> */}
            {/* <div className=' w-full' style={{height:"500px", backgroundImage:`url(${BgCol})` , backgroundSize:"cover"}}>

            </div> */}
        </div>

    </div>
)
}
