import React from 'react'
import Solution from './Solution'
import File from "../../../asset/images/fileAttent.png"
import Borne from "../../../asset/images/borne.png"
import Affichage from "../../../asset/images/affichageD.png"
import Vent from "../../../asset/images/vent.png"
import Pointeuse from "../../../asset/images/pointage.png"
export default function FullSolution() {
  return (
    <div className='fullSol md:flex-row flex justify-evenly items-center flex-col m-auto border-solid w-full my-10'>
      <Solution link="solution/systeme-gestion-file-attente" title="FILE D'ATTENTE" desc="QMaster est une solution innovante qui vous permet de gérer efficacement votre flux d'attente en optimisant vos ressources grâce à QManagement." img={File} />
      <Solution title="BORNES" desc="Des bornes interactives adaptées à vos besoins : bornes d'information, de consultation, de paiement, de satisfaction ou d'affichage publicitaire." img={Borne} />
      <Solution link="solution/affichage-dynamique" title="AFFICHAGE DYNAMIQUE" desc="T2F SYSTEMS vous offre la possibilité de configurer et d'afficher facilement votre playlist." img={Affichage} />
      <Solution link="solution/logiciel-caisse" title="SOLUTIONS POINTS DE VENTE" desc="Notre gamme de produits T2F vous offre tout ce dont vous avez besoin pour gérer efficacement votre commerce." img={Vent} />
      <Solution link="solution/pointT2F" title="POINTAGE" desc="PointT2F est une solution de pointage biométrique qui vous offre un système de contrôle d'accès électronique performant pour votre entreprise." img={Pointeuse} />
    </div>
  )
}
