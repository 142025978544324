import './App.css';
import Footer from './Components/Navbar/Footer';
import  Header from './Components/Navbar/Header';
import {Routes , Route} from "react-router-dom"
import Accueil from "./Components/Accueil"
import FullSociete from './Components/CompAccueil/CompSocite/FullSociete';
import SGFA from './Components/Solutions/SGFA';
import AffichageD from './Components/Solutions/AffichageD';
import Caisse from './Components/Solutions/Caisse';
import Pointage from './Components/Solutions/Pointage';


export default function App() {
  return (
    <div className='flex flex-col justify-between'>
      <Header/>
        <Routes>
          <Route path='/' element={<Accueil/>} />
          <Route path='solution/systeme-gestion-file-attente' element={<SGFA/>} />
          <Route path='solution/affichage-dynamique' element={<AffichageD/>} />
          <Route path='solution/logiciel-caisse' element={<Caisse/>} />
          <Route path='solution/pointT2F' element={<Pointage/>} />
        </Routes>
      <Footer/>
      
    </div>
  )
}




