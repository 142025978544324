import React from 'react'
import logo from "../../asset/images/T2FSystems.png"
import { useState } from 'react'
import Tele from "../../asset/images/phone-call.png"
import Fax from "../../asset/images/fax-machine.png"
import Mail from "../../asset/images/mail-recipient.png"
import Facebook from "../../asset/images/facebook-logo.png"
import Twitter from "../../asset/images/twitter.png"
import Instagram from "../../asset/images/instagram.png"
import Linkdin from "../../asset/images/linkedin.png"
export default function FooterResp(props) {
    return (
        <div>
            
        </div>
    )
}
