import React, { useEffect, useState } from 'react';

export default function CounterDiv(props) {
  const [counts, setCounts] = useState(0);
  const step = 1; // Vitesse de l'animation

  useEffect(() => {
    const target = props.number;
    
    const upData = () => {
      setCounts((prv) => {
        if (prv < target) {
          return prv+step
        } else {
          return target; // S'assurer que l'on atteint la cible
        }
      });
    };
    
    const interval = setInterval(upData,40); // Mettre à jour toutes les millisecondes

    // Nettoyage de l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [props.number]); // Redémarrer l'animation si le nombre change

  return (
    <div className='shadow-xl h-36 md:w-60 my-10 w-full  pb-3 pl-5 rounded-lg bg-gradient-to-br from-indigo-50 to-emerald-50   flex flex-col justify-evenly'>
      <div className='flex items-center h-30 '>
        <img src={props.image} alt='non' width={35} className='mr-5' />
        <div className='text-3xl font-extrabold'>{counts}</div>
        <p className='text-3xl font-extrabold'>{props.unit}</p>
        <p className='text-3xl font-extrabold'>+</p>
      </div>
      <div className='flex items-center h-14 '>
        <p className='text-xl font-sans text-gray-700'>{props.text}</p>
      </div>
    </div>
  );
}
