import React from 'react'
import Background from "../../asset/images/qmatic-bg-square-2.png"
export default function Header(props) {
  return (
    <div>
      <div className='w-full flex items-center mb-96' style={{backgroundImage:`url(${Background})`, height:"530px" , backgroundSize:"contain" , backgroundRepeat:"no-repeat" , backgroundPosition:"right"}}>
            <div style={{height:"70%" , width:"50%" }} className='flex justify-end items-center  '>
              <div style={{height:"30%" , width:"90%" , lineHeight:"60px" , fontSize:"45px"}} className='  text-green-500 font-bold'>
                {props.title}
              </div>
            </div>
      </div>
    </div>
  )
}
