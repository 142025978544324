import React, { useState ,useEffect} from 'react';
import "../../style/header.css"
import fleche from "../../asset/images/fleche.png"
import logo from "./../../asset/images/T2FSystems.png"
import NavResp from './NavResp';
import Navbar from "./NavDataJSON/Navbar"
import Facebook from "../../asset/images/facebook-logo.png"
import Twitter from "../../asset/images/twitter.png"
import Instagram from "../../asset/images/instagram.png"
import Linkdin from "../../asset/images/linkedin.png"
import { Link } from 'react-router-dom';
export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled , setIsScrolled]=useState(false)
  const [isScrolledH , setIsScrolledH]=useState(true)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    
  };
  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY < 280 && window.scrollY >200){
        setIsScrolledH(false)
      }
      else{
        setIsScrolledH(true)
      }
      if (window.scrollY >= 280  ) {
        setIsScrolled(true);
        setIsScrolledH(true)
        
      }
       else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);


  }, []);
  return (
    <div className="flex flex-col ">
      <div className='hidden lg:block '>
      <div className=' px-5 flex justify-between   items-center  h-32' style={{visibility:isScrolled ? "hidden" : "visible"}}>
        <Link to="/" className='' id='logo-Web'  style={{backgroundImage:`url(${logo})` , backgroundSize:"70%"}}>

        </Link>
        <div className='    flex justify-between items-center  ' >

                                    <a className='m-3' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Twitter})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='m-3' style={{width:"36px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Facebook})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='m-3' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Instagram})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='m-3' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Linkdin})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                </div>
      </div>

      </div>
    
      <nav className={` w-full ${isScrolled ? "lg:fixed" : "static"} lg:top-0 z-10 drop-shadow-md  mt-0`} style={{backgroundColor: "white" , top: isScrolled ? 0 : "auto" , height:isScrolledH ? "50px" : "0px"  , transition: "all 0.4s" }}>

        <div className="flex  items-center justify-between " style={{height:"50px"  }}>
          <div className="flex items-center  w-full h-full justify-between" >
            <div className="hidden lg:block " style={{width:"100%"}}>
              <div className="m-auto flex  font-bold justify-evenly " style={{width:"80%"}}>
                
                {/* <div>

                <div className='flex flex-row justify-between items-center' >
                  <a href="#" className="flex rounded-md px-10 justify-between items-center  py-2 text-black transition-all duration-100 font-bold  hover:text-green-700">À PROPOS DE T2F
                    
                  <div className='fleche-Nav ml-3' style={{backgroundImage:`url(${fleche})`, width:"12px", height:"12px" , backgroundSize:"cover" , backgroundPosition:"center"}}>

                  </div>
                  </a>
                </div>
                
                </div>

                <div className='flex flex-row justify-between items-center' >
                  <a href="#" className="flex rounded-md px-10 justify-between items-center  py-2 text-black transition-all duration-100 font-bold  hover:text-green-700">NOS SOLUTIONS
                    
                  <div className='fleche-Nav ml-3' style={{backgroundImage:`url(${fleche})`, width:"12px", height:"12px" , backgroundSize:"cover" , backgroundPosition:"center"}}>

                  </div>
                  </a>
                </div>

                <div className='flex flex-row justify-between items-center' >
                  <a href="#" className="flex rounded-md px-10 justify-between items-center  py-2 text-black transition-all duration-100 font-bold  hover:text-green-700">PRODUIT
                    
                  <div className='fleche-Nav ml-3' style={{backgroundImage:`url(${fleche})`, width:"12px", height:"12px" , backgroundSize:"cover" , backgroundPosition:"center"}}>

                  </div>
                  </a>
                </div>
                  <a href="#" className="flex rounded-md px-10 py-2 text-black font-bold  transition-all delay-100 hover:text-green-700">REALISATIONS</a>
                  <a href="#" className="flex rounded-md px-10 py-2 text-black font-bold  transition-all delay-100 hover:text-green-700">CONTACT</a>
                  <a href="#" className="flex rounded-md px-10 py-2 text-black font-bold  transition-all delay-100 hover:text-green-700">ESPACE CLIENT</a> */}
                  
                    <Navbar / >
                  
              </div>
            </div>

            <div className='lg:hidden bg-white h-full flex ' style={{width:"95%" }}>
              <div style={{backgroundImage:`url(${logo})` , width:"15%", backgroundSize:"cover" ,height:"100%" ,backgroundPosition:"center"}}>

              </div>
            </div>
            <div className="flex lg:hidden justify-center h-full items-center " style={{width:"max-width"}}>
              <button onClick={toggleMenu} className="bg-white relative inline-flex items-center justify-center h-full w-full text-black mr-3 font-extrabold  hover:text-green-700">
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <svg className=" block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className=" block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-16 6h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>


      </nav>
        <div style={{backgroundColor:"#303030"}} id={isMenuOpen ? "open" : "close"} className="mt-1 lg:hidden flex flex-col justify-start">

          <NavResp 
            flecheImg={fleche}
            navDos={3} 
            navDosTitles={["SOCIETE","CERTIFICAT","ACTUALITE"]}  
            navTitle="À PROPOS DE T2F"
            fleche= "true"   
          />

          <NavResp 
          flecheImg={fleche}  
          navDos={7} 
          navDosTitles={["SGFA","CAISSE","AFFICHAGE DYNAMIQUE","SONDAGE CLIENT","POINTAGE","BORNE INTERACTIVE","CONSOMMABLE"]} 
          navTitle="NOS SOLUTIONS" 
          fleche= "true" 
          />

          <NavResp 
            flecheImg={fleche}  
            navDos={7} 
            navDosTitles={["SGFA","CAISSE","AFFICHAGE DYNAMIQUE","SONDAGE CLIENT","POINTAGE","BORNE INTERACTIVE","CONSOMMABLE"]} 
            navTitle="PRODUIT" 
            fleche= "true" 
          />
          <NavResp 
            flecheImg={fleche}  
            navTitle="REALISATIONS"
            
          />

          <NavResp 
            flecheImg={fleche}  
            navTitle="CONTACT" />

          <NavResp 
            flecheImg={fleche}  
            navTitle="ESPACE CLIENT"  
          />

          
        </div>
    </div>
  );
}
