import React from 'react'
import Certificat from "../../../asset/images/business.png"
export default function Societe(props) {
    let hundelHover=()=>{
        const prevEtat=document.getElementById(`apRotate${props.item}`)
        const fEtat=document.getElementById(`avRotate${props.item}`)
        const allCompt = document.getElementById(`comp${props.item}`)
        allCompt.style.backgroundColor="#22C568"
        fEtat.style.transform="rotateY(190deg)"
        allCompt.style.transform="rotateY(190deg)"
        allCompt.style.transition="transform 0.3s"
        prevEtat.style.display="none"
        fEtat.style.display="block"
        
    }
    const hundelLeave=()=>{
        const prevEtat=document.getElementById(`apRotate${props.item}`)
        const fEtat=document.getElementById(`avRotate${props.item}`)
        const allCompt = document.getElementById(`comp${props.item}`)
        allCompt.style.backgroundColor="white"
        prevEtat.style.transform="rotateY(0deg)"
        allCompt.style.transform="rotateY(0deg)"
        fEtat.style.display="none"
        prevEtat.style.display="block"
        
    }
  return (
    <div id={"comp"+props.item} className='comp my-10  shadow-green-500 shadow-sm flex justify-center items-center rounded-2xl border-green-200 border' onClick={hundelHover} onMouseLeave={hundelLeave}  style={{height:"290px" , width:"340px"}}>
        <div id={"apRotate"+props.item} className='hover:cursor-pointer w-4/6 justify-center h-4/6 mt-8' >
            <div  style={{height:"60%" , backgroundImage:`url(${props.img})` , backgroundSize:"50%" , backgroundRepeat:"no-repeat"  , backgroundPosition:"center"}}>

            </div>
            <div className=' flex font-bold text-green-500 justify-center items-start' style={{height:"25%", fontSize:"20px"}}>
                {props.title}
            </div>
        </div>
        <div id={"avRotate"+props.item}  className='hidden h-4/6 w-4/5'>
            <div className='w-full  items-center justify-center text-white flex' style={{height:"70%"}}>
            <p className='text-center'>{props.desc}</p>
            </div>
            <a className='text-white w-full hover:cursor-pointer font-extrabold flex items-center justify-center' >
                En savoir plus
            </a>
        </div>
    </div>
  )
}
