import React from 'react'
import CounterDiv from './CounterDiv'
import image1 from "../../../asset/images/portable.png"
import image2 from "../../../asset/images/mallette.png"
import image3 from "../../../asset/images/client.png"
import image4 from "../../../asset/images/parametres.png"
export default function FullCounter() {
    return (
    <div className='container m-auto flex-col md:flex-row  flex  md:justify-evenly items-center'>
        <CounterDiv text="Solutions logiciel réalisés avec succès" number="20" unit="K" image={image1}/>
        <CounterDiv text="Années d'expérience dans le secteur" number="10"   image={image2}/>
        <CounterDiv text="Clients satisfaits" number="150"  image={image3}/>
        <CounterDiv text="Interventions SAV réussies" number="100"  image={image4}/>
    </div>
    )
}
