import React from 'react'
import Aventages from './Aventage'
import Lumiere from "../../../asset/images/light-source.png"
import Securite from "../../../asset/images/security-lock.png"
import Reclamation from "../../../asset/images/project-manager.png"
import Instalation from "../../../asset/images/computer-setting.png"
import Statistique from "../../../asset/images/statistical-chart.png"
import Team from "../../../asset/images/team-management.png"
export default function FullAventages() {
  return (
    <div className='forFooter w-full  flex justify-center   my-10' >
      <div className='flex flex-col md:justify-between items-center m-auto md:m-0  ' style={{width:"78%"}}>
        <div className='flex w-full md:flex-row flex-col m-auto justify-evenly  items-center'>
          <Aventages title="Innovation creativité" desc="T2F SYSTEMS suit constament l'evolution technologique , pour permettre de rester sur les devants" img={Lumiere}/>
          <Aventages title="Espace sécurisé" desc="Accés sécurisé pour le site en https et par login et mot de passe fournit a notre client" img={Securite}/>
          <Aventages title="Suivi réclamations" desc="possibilité d'ajouter une réclamation, d'en faire le suivi et valider la cloture" img={Reclamation}/>
        </div>
        <div className='flex w-full md:flex-row flex-col m-auto justify-evenly items-center'>
          <Aventages  title="Suivi installations" desc="Votre espace vous permattra de faire le suivi de vos instalations , déménagement , réaménagement" img={Instalation}/>
          <Aventages title="Etats statistiques" desc="Des états statistiques répondant à votre besoin de suivi de la qualité de notre service" img={Statistique}/>
          <Aventages title="Equipe professionnel" desc="Une équipe de professionnel experimentée , dans les domaines de la nouvelle technologie " img={Team}/>
        </div>
      </div>
    </div>
  )
}
