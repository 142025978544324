import React from 'react'
export default function Aventages(props) {
  return (
    <div className='my-5 md:my-0 border-solid border-emerald-200 h-52 rounded flex flex-col justify-center items-center' style={{borderWidth:"1px" , width:"330px"}}>
      <div className=' border-solid flex flex-col justify-evenly ' style={{width:"90%" , height:"90%"}}>
        <div className='flex ' style={{height:"32%"}}>

          <div className='w-3/12  flex justify-evenly items-center '  >
            <div className=' mr-6'style={{width:"70%", height:"75%",backgroundImage:`url(${props.img})` , backgroundSize:"cover" , backgroundRepeat:"no-repeat" , backgroundPosition:"center"}}>

            </div>
          </div>
          <div className='w-8/12 flex items-center text-gray-900 font-body  ' style={{fontSize:"20px", lineHeight: "24px"}}>
          {props.title}
          </div>
        </div>
        <div className='w-full border-solid font-normal flex justify-center' style={{height:"55%"}}>
        <p style={{ fontSize:"16px" , width:"85%"}}> {props.desc}</p>
        </div>
      </div>
    </div>
  )
}
