import React from 'react'
import {useState , useEffect} from "react"
export default function NavResp(props) {
    const [isFleche , setIsFleche]=useState(false)
    const [isNavDosOppen , setIsNavDosOpen]=useState(false) 
    useEffect(()=>{
        if(props.fleche == "true"){
            setIsFleche(true)
            
        }
        else{
            setIsFleche(false)
        }
    } , [])
    const changeStateNavDos=()=>{
      setIsNavDosOpen(!isNavDosOppen)
    }
    
    const clickFleche = (event) => {
      const pointage = event.target.parentElement.parentElement;
      const sibling = event.target.parentElement.nextSibling;
      const divParentDos=document.createElement("div")
      divParentDos.style.flex="column"
      divParentDos.style.justifyContent="start"
      divParentDos.style.width="100%"
      divParentDos.className="superDos"
      divParentDos.style.height="0px"
      divParentDos.style.transition="all 4s"
      divParentDos.style.backgroundColor="#3d3d3d"
      
        if(isNavDosOppen ){
          if(sibling.className=="superDos"){
            sibling.remove()
            changeStateNavDos()
          }
        }
        else{
          for (let i = 0; i < props.navDos; i++) {

            const divNav = document.createElement("div");
            divNav.style.borderTop="#404040 solid 1px"
  
            if(props.navTitle==="À PROPOS DE T2F"){
              let heightParentDos=String(50*3+"px")
              divNav.innerHTML = `
              <div style="border-color: #404040;" class="px-14 py-3 flex flex-row justify-between items-center">
              <a href="#" class="text-white font-medium hover:text-lime-300">
              ${props.navDosTitles[i]}
              </a>
              `;
              divParentDos.appendChild(divNav)
              divParentDos.style.height=heightParentDos
  
            }
            else{

              let heightParentDos=String(50*props.navDos+"px")
              divNav.innerHTML = `
                <div style="border-color: #404040;" class="px-14 py-3 flex flex-row justify-between items-center">
                  <a href="#" class="text-white font-medium hover:text-lime-300">
                    ${props.navDosTitles[i]}
                  </a>
                
                <button 
                  class='niv2 fleche-Nav ml-3 animate-bounce ' 
              style="
              background-image:url(${props.flecheImg});
              width:18px;
                height:18px ; 
                background-size:80% ;
                background-position:center;
                background-repeat:no-repeat;
                animation-iteration-count:infinite;
                animation-duration:1s;
                "
                
                >
                </div>
            </button>
              `;
              divParentDos.appendChild(divNav)
              divParentDos.style.height=heightParentDos
            }
            pointage.insertBefore(divParentDos, sibling);
          }
          changeStateNavDos()
        
        };
        }

      


    return (
    <div style={{borderColor:"#404040"}} className=' border-solid border-t-2 px-5 py-3 flex flex-row justify-between items-center' >
        <a href="#" className="   justify-between items-center  text-white font-medium hover:text-green-500">{props.navTitle}
        </a>
        {isFleche == true && 
        <button 
        className='buttonDos fleche-Nav ml-3 animate-bounce ' id={isNavDosOppen ? "navDosOppen" : "navDosClose"}
        style={{
            backgroundImage:`url(${props.flecheImg})`,
            width:" 18px", 
            height:"18px" , 
            backgroundSize:"80%" , 
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            animationIterationCount:"infinite",
            animationDuration:"1s",

            }}
            onClick={clickFleche}
            >
        </button>
        
        
    }   

  </div>
  )
}
