import React from 'react'
import logo from "../../asset/images/T2FSystems.png"
import { useState } from 'react'
import Tele from "../../asset/images/phone-call.png"
import Fax from "../../asset/images/fax-machine.png"
import Mail from "../../asset/images/mail-recipient.png"
import Facebook from "../../asset/images/facebook-logo.png"
import Twitter from "../../asset/images/twitter.png"
import Instagram from "../../asset/images/instagram.png"
import Linkdin from "../../asset/images/linkedin.png"
import FooterResp from './FooterResp'
export default function Footer() {
    const [footerElem , setFooterElem] =useState(
        [
            {
                title: "À propos de T2F",
                submenu:[
                    {
                        title: "Societé",
                        href:"#"

                    },
                    {
                        title: "Certificat",
                        href:"#"
                    },
                    {
                        title: "Actualité",
                        href:"#"
                    }
                    
                ]
            },
            {
                title: "Nos solutions",
                submenu: [
                    {
                        title: "SGFA",
                        href:"#"
                    },
                    {
                        title:"Caisse",
                        href:"#"
                    },
                    {
                        title:"Affichage dynamique",
                        href:"#"
                    },
                    {
                        title:"Sondage client",
                        href:"#"
                    },
                    {
                        title:"Pointage",
                        href:"#"
                    },
                    {
                        title:"Borne interactive",
                        href:"#"
                        
                    },
                    {
                        title:"Consommable",
                        href:"#"
                    }
                ]
            },
            {
                title: "Produit",
                submenu: [
                    {
                        title: "SGFA",
                        href:"#"
                    },
                    {
                        title:"Caisse",
                        href:"#"
                    },
                    {
                        title:"Affichage dynamique",
                        href:"#"
                    },
                    {
                        title:"Sondage client",
                        href:"#"
                    },
                    {
                        title:"Pointage",
                        href:"#"
                    },
                    {
                        title:"Borne interactive",
                        href:"#"
                        
                    },
                    {
                        title:"Consommable",
                        href:"#"
                    }
                ]
            }
        ]

    )
return (
    <div>

        <div className='hidden md:block'>

            <div className='border-solid  p-6 flex justify-center' style={{height:'450px',backgroundColor:"#f2f2f2"}}>
                <div className=' h-full flex flex-col' style={{width:"80%"}}>
                    <div className='top-Footer  flex justify-between w-full h-full ' style={{height:"70%"}}>
                        <div className='tl-Footer h-full justify-between flex' style={{width:"70%"}}>
                            {footerElem.map((elem)=>{
                                return (
                                <div className=' justify-between flex-col flex border-solid h-full' style={{width:"30%"}}>
                                    <div id='titles-elem-footer ' style={{height:"15%",fontFamily:"arial"}} className='text-green-500 font-bold ' >
                                        {elem.title}
                                    </div>
                                    <div className='flex-col justify-between h-full w-full'>

                                            {elem.submenu.map((submenu)=>{
                                                    return(
                                                        <div >
                                                            <a className='hover:text-green-500 cursor-pointer text-sm' >{submenu.title}</a>

                                                        </div>
                                                    )
                                            })}
                                    </div>   
                                </div>
                                )
                            })}

                        </div>
                        <div style={{color:"#5a5a5a" , width:"25%"}} className='tr-Footer text-sm ' >
                            <div className='flex w-full ' >
                                <div style={{width:"10%" }} className='flex justify-center items-center mt-2'>
                                    <div  style={{backgroundImage:`url(${Tele})`,backgroundPosition:"center" , backgroundSize:"cover" , backgroundRepeat:"no-repeat" , width:"19px" , height:"19px"}}>

                                    </div>
                                </div>
                                <div className='flex flex-col h-full' style={{width:"90%"}}>
                                    <p className='flex items-center' style={{width:"50%"}}>+212 522 22 95 36</p>
                                    <p className='flex items-center' style={{width:"50%"}}>+212 630 66 90 93</p>
                                </div>

                            </div>
                            <div className='flex w-full mt-2' >
                                <div className=' mr-3' style={{backgroundImage:`url(${Fax})`,backgroundPosition:"center" , backgroundSize:"cover" , backgroundRepeat:"no-repeat" , width:"19px" , height:"19px" }}>

                                </div>
                                <p>+212 522 47 36 71</p>
                            </div>
                            <div className='flex w-full mt-2' >
                                <div className=' mr-3' style={{backgroundImage:`url(${Mail})`,backgroundPosition:"center" , backgroundSize:"cover" , backgroundRepeat:"no-repeat" , width:"19px" , height:"19px" }}>

                                </div>
                                <p>info@T2F.ma</p>
                            </div>
                            <p className='mt-2'>202. Bd Abdelmoumen. Nº5 RDC Casablanca - Maroc</p>
                            <div className='flex items-center border-solid ' style={{height:"30%"}}>
                                <div className='flex justify-between items-center w-full border-solid  ' >

                                    <a className='' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Twitter})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='' style={{width:"36px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Facebook})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Instagram})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                    <a className='' style={{width:"35px",height:"35px"}}>
                                        <div className='w-full h-full rounded-full' style={{backgroundImage:`url(${Linkdin})`, backgroundSize:"cover" , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>

                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bot-Footer flex justify-between items-center '  style={{height:"30%"}}>
                        <div id='logo-Footer' style={{backgroundImage:`url(${logo})` , width:"20%" , height:"90%"  , backgroundSize:"cover" , backgroundPosition:"center"}}>

                        </div>
                        <div id='copyR-Footer' className='flex    justify-center text-gray-700 text-sm font-sans' style={{ width:"30%"}}>
                            © Copyrights 2024. All Rights Reserved.
                        </div>
                        <div id='Privacy-Footer' className='flex items-end text-xs  justify-center text' style={{ width:"20%",height:"40%"}}>
                            Privacy Policy
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='md:hidden'>
            <FooterResp elem = {footerElem}/>
        </div>
    </div>
)
}
