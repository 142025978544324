
    export const MenuItemsJ = [
        {
            "title": "À PROPOS DE T2F",
            "href":"",
            "submenu": [
                {
                    "title": "SOCIETE",
                    "href":""
                },
                {
                    "title": "CERTIFICAT",
                    "href":""
                },
                {
                    "title": "ACTUALITE",
                    "href":""
                }
                
            ]
        },
        {
            "title": "NOS SOLUTIONS",
            "href":"",
            "submenu": [
                {
                    "title": "SGFA",
                    "href":"/solution/systeme-gestion-file-attente",
                    "submenu":[
                        {
                            "title":"M.G",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"M.C",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"BORNE",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"CONSOLE",
                            "submenu":[
                                {
                                    "title":"PHYSIQUE"
                                },
                                {
                                    "title":"INFORMATIQUE"
                                }
                                
                            ]
                        }
                    ]
                },
                {
                    "title":"CAISSE",
                    "href":"/solution/logiciel-caisse",
                    "submenu":[
                        {
                            "title":"CAISSE M1,M2"
                            
                        },
                        {
                            "title":"TIROIR CAISSE"
                        },
                        {
                            "title":"LECTEUR RFID"
                        },
                        {
                            "title":"TABLETTE"
                        }
                    ]
                },
                {
                    "title":"AFFICHAGE DYNAMIQUE",
                    "href":"/solution/affichage-dynamique",
                },
                {
                    "title":"SONDAGE CLIENT",
                    "href":"",
                },
                {
                    "title":"POINTAGE",
                    "href":"/solution/pointT2F"
                },
                {
                    "title":"BORNE INTERACTIVE",
                    "href":"",
                    "submenu":[
                        {
                            "title":"BORNE PAIEMENT"
                        },
                        {
                            "title":"BORNE CONSULTATION"
                        }
                        ,
                        {
                            "title":"BORNE INFORMATION"
                        }
                        ,
                        {
                            "title":"BORNE SGFA"
                        }
                    ]
                },
                {
                    "title":"CONSOMMABLE",
                    "href":"",
                    "submenu":[
                        {
                            "title":"ROULEAUX"
                        },
                        {
                            "title":"ETIQUETTE"
                        }
                    ]
                }
            
            ]
        },
        {
            "title": "PRODUIT",
            "href":"",
            "submenu": [
                {
                    "title": "SGFA",
                    "href":"",
                    "submenu":[
                        {
                            "title":"M.G",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"M.C",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"BORNE",
                            "submenu":[
                                {
                                    "title":"M1"
                                },
                                {
                                    "title":"M2"
                                },
                                {
                                    "title":"M3"
                                }
                            ]
                        },
                        {
                            "title":"CONSOLE",
                            "submenu":[
                                {
                                    "title":"PHYSIQUE"
                                },
                                {
                                    "title":"INFORMATIQUE"
                                }
                                
                            ]
                        }
                    ]
                },
                {
                    "title":"CAISSE",
                    "href":"",
                    "submenu":[
                        {
                            "title":"CAISSE M1,M2"
                        },
                        {
                            "title":"TIROIR CAISSE"
                        },
                        {
                            "title":"LECTEUR RFID"
                        },
                        {
                            "title":"T1BLETTE"
                        }
                    ]
                },
                {
                    "title":"AFFICHAGE DYNAMIQUE",
                    "href":""
                },
                {
                    "title":"SONDAGE CLIENT",
                    "href":""
                },
                {
                    "title":"POINTAGE",
                    "href":""
                },
                {
                    "title":"BORNE INTERACTIVE",
                    "href":"",
                    "submenu":[
                        {
                            "title":"BORNE PAIEMENT"
                        },
                        {
                            "title":"BORNE CONSULTATION"
                        }
                        ,
                        {
                            "title":"BORNE INFORMATION"
                        }
                        ,
                        {
                            "title":"BORNE SGFA"
                        }
                    ]
                },
                {
                    "title":"CONSOMMABLE",
                    "href":"",
                    "submenu":[
                        {
                            "title":"ROULEAUX"
                        },
                        {
                            "title":"ETIQUETTE"
                        }
                    ]
                }
            
            ]
        },
        {
            "title":"REALISATIONS",
            "href":""
        },
        {   
            "title":"CONTACT",
            "href":""
        },
        {
            "title":"ESPACE CLIENT",
            "href":""
        }
    ]
