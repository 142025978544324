import React from 'react'
import Pointeuse from "../../../asset/images/pointage.png"
import { Link } from 'react-router-dom'
export default function Solution(props) {
  return (
    <Link to={props.link} className='my-10 solution shadow-xl hover:cursor-pointer flex flex-col rounded-2xl' style={{height:"460px" , width:"260px"}}>
      <div className='solutionBg rounded-t-2xl  bg-slate-100 flex justify-center items-end' style={{width:"100%" , height:"55%", backgroundImage:`url(${props.img})`  , backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}>
        <div className='consulterH  flex items-center justify-center text-white  text-sm font-bold ' style={{width:'42%' , backgroundColor:"rgba(0,120,40,0.80)" }}>
          Consulter
        </div>
      </div>
      <div className='p-4'>
        <div className='text-emerald-800 font-body text-center font-bold ' style={{fontSize:"17px", lineHeight:"20px"}} >
        {props.title}
        </div>
        <div className='text-center mt-3  text-sm'>
          {props.desc}
        </div>
      </div>
    </Link>
  )
}
